import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { IOption } from '../models/option.model';
import useTranslations from './use-translations';

interface IUseLanguageConfigQueryResult {
    languageConfig: {
        languages: string[];
        defaultLanguage: string;
    };
}

interface IUseLanguageConfigConfig {
    disabled?: string[];
}

export const useLanguageConfig = (config: IUseLanguageConfigConfig | undefined = {}) => {
    const { disabled } = config;
    const t = useTranslations('UseLanguageConfig');
    const { languageConfig } = useStaticQuery<IUseLanguageConfigQueryResult>(query);

    const languages = useMemo(() => {
        return [
            languageConfig.defaultLanguage,
            ...languageConfig.languages.filter(
                (language) => language !== languageConfig.defaultLanguage
            ),
        ];
    }, [languageConfig.defaultLanguage, languageConfig.languages]);

    const options: IOption[] = languages.map((language) => {
        return {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label: t[language],
            value: language,
            enabled: (disabled && !disabled.includes(language)) || true,
        };
    });
    return {
        options,
        languages,
        defaultLanguage: languageConfig.defaultLanguage,
    };
};

const query = graphql`
    query {
        languageConfig {
            defaultLanguage
            languages
        }
    }
`;
