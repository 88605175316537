import { IMedia } from '@alterpage/gatsby-source-alterpress';

export enum ESharedFileRelation {
    default = 1,
    privacyPolicy,
    regulations,
    affiliateRegulations = 4,
}

export interface ISharedFile {
    linkId: string;
    locale: string;
    slug: string;
    media: IMedia[];
    relation: ESharedFileRelation;
}
