import { useState, useEffect, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { ESharedFileRelation, ISharedFile } from '../models/shared-file.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { getNodes } from '../utils/get-nodes';
import { useLanguageConfig } from './use-language-config';

interface IUseSharedFileQueryResult {
    allSharedFile: IQueryAllResult<ISharedFile>;
}

export const useSharedFile = (relation: ESharedFileRelation) => {
    const { locale } = usePageContext();
    const languageConfig = useLanguageConfig();
    const localePrefix = languageConfig.defaultLanguage !== locale ? `${locale}/` : '';

    const { allSharedFile } = useStaticQuery<IUseSharedFileQueryResult>(query);
    const sharedFiles = useMemo(() => {
        return getNodes(allSharedFile);
    }, [allSharedFile]);
    const [file, setFile] = useState(getSharedFile(relation, locale, sharedFiles));

    useEffect(() => {
        setFile(getSharedFile(relation, locale, sharedFiles));
    }, [relation, locale, sharedFiles]);

    if (!file?.media[0]) return;

    return {
        data: file,
        pathname: `/${localePrefix}${file.slug}.${file.media[0].url.split('.').pop()}`,
    };
};

function getSharedFile(relation: ESharedFileRelation, locale: string, files: ISharedFile[]) {
    return files.find((file) => file.locale === locale && file.relation === relation);
}

const query = graphql`
    query {
        allSharedFile {
            edges {
                node {
                    linkId
                    relation
                    locale
                    slug
                    media {
                        ...mediaFields
                    }
                }
            }
        }
    }
`;
