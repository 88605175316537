import * as Yup from 'yup';

import { TFormFieldsFactory } from '../models/form-field.model';

export const affiliateRulesForm: TFormFieldsFactory<
    'AffiliateProgramRules',
    { rulesUrl: string }
> = ({ t, rulesUrl }) => [
    {
        name: 'rules',
        type: 'checkbox',
        label: t.consent.label(rulesUrl),
        initialValue: '',
        schema: Yup.string().required(t.consent.required),
    },
    {
        name: 'submit',
        type: 'submit',
        label: t.submit,
    },
];
